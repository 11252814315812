import React, {memo} from 'react';
import {Button, Input, Small} from "../../styles/shared";
import theme from "../../styles/theme";
import {Link} from "react-router-dom";
import PasswordInput from "../../components/PasswordInput";

export const LoginAdultForm = memo(({values, onChange, onSubmit, loading}) => {
  return (
    <form onSubmit={onSubmit}>
      <Input
        type="email"
        name="email"
        placeholder="Почта учителя"
        onChange={e => onChange(e.target.value, e.target.name)}
      />
      <PasswordInput
        name="password"
        placeholder="Пароль"
        onChange={onChange}
        value={values.password}
      />
      <Button type="submit" block disabled={loading}>{loading ? `Проверяем...` : `Войти`}</Button>
      <Small color={theme.mute}>
        <Link to='/restore'>Забыли пароль?</Link>
        <div>&nbsp;</div>
      </Small>
    </form>
  );
});