import styled from "styled-components";
import theme from "./theme";
import bp from './bp';
import bgLeft from '../assets/images/bg-global-left.svg'
import bgRight from '../assets/images/bg-global-right.svg'

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background-color: ${theme.light};
  
  ${bp.sm`
  background-image: url(${bgLeft}), url(${bgRight});
  background-repeat: no-repeat, no-repeat;
  background-position: left bottom, right bottom;
  background-size: 20vw, 30vw;
  `};
`;

export const Main = styled.main`
  flex: 1 0 auto;
  display: block;
  ${bp.sm`min-height: 650px`};
`;

export const Container = styled.div`
  max-width: ${props => props.width ? `${props.width}px` : '1300px'};
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
`;

export const Image = styled.img`
  max-width: 100%;
  height: auto;
`;

export const H1 = styled.h1`
  font-size: 200%;
  margin-bottom: ${props => props.crop ? 0 : '1rem'};
`;

export const H2 = styled.h2`
  font-size: 160%;
  margin-bottom: ${props => props.crop ? 0 : '1rem'};
`;

export const H3 = styled.h3`
  font-size: 120%;
  margin-bottom: ${props => props.crop ? 0 : '1rem'};
`;

export const Large = styled.div`
  font-size: 120%;
  color: ${props => props.color ? props.color : 'inherit'};
`;

export const Small = styled.small`
  display: inline-block;  
  color: ${props => props.color ? props.color : 'inherit'};
`;

export const Button = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${theme.primary};
  font-family: ${theme.fontTitle};
  font-size: 95%;
  letter-spacing: .5px;
  text-transform: uppercase;
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  pointer-events: ${props => props.disabled ? "none" : "auto"};
  opacity: ${props => props.disabled ? .5 : 1};
  border-radius: 4px;
  text-align: center;
  line-height: 1.1;
  background-color: ${props => props.outline ? '#fff' : theme.primary};
  padding: .3rem .75rem 0;
  margin-bottom: ${props => props.crop ? 0 : '1rem'};
  min-width: ${props => props.minWidth ? props.minWidth : '10rem'};
  width: ${props => props.block ? '100%' : 'auto'};
  min-height: 42px;
  color: ${props => props.outline ? theme.primary : `#fff`};
  transition: .2s all ease-in-out;
  
  &:hover {
    filter: brightness(90%);
  }
  
  &:active {
    filter: brightness(80%);
  }
  
  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0,0,0, .1);
  }
`;

export const ButtonLink = styled.button`
  border: none;
  font-family: ${theme.fontTitle};
  font-size: inherit;
  padding: 0;
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  pointer-events: ${props => props.disabled ? "none" : "auto"};
  opacity: ${props => props.disabled ? .5 : 1};
  background-color: transparent;
  box-shadow: none;
  color: ${props => props.color ? props.color : theme.primary};;
  line-height: inherit;
  
  &:hover {
    filter: brightness(90%);
  }
  
  &:active {
    filter: brightness(80%);
  }
  
  &:focus {
    outline: none;
  }
`;

export const Input = styled.input`
  padding: .25rem .5rem;
  width: 100%;
  min-height: 42px;
  font-size: inherit;
  border-radius: 4px;
  -webkit-appearance: none;
  border: 1px solid ${theme.gray};
  outline: none;
  box-shadow: none;
  transition: all .2s;
  margin-bottom: .75rem;
  line-height: 1.1;
  
  &:focus {
    outline: none;
    border-color: transparent;
    box-shadow: 0 0 0 2px ${theme.primary};
  }
  
  &:-webkit-autofill, &:focus:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 100px white inset;
  }
`;

export const Label = styled.label`
  span {
    font-size: .8rem;
    display: inline-block;
    margin-bottom: .25rem;
    color: ${theme.mute}
  }
`

export const Select = styled.select`
  padding: .25rem .5rem;
  width: 100%;
  min-height: 42px;
  font-size: inherit;
  border-radius: 4px;
  -webkit-appearance: none;
  background: transparent;
  border: 1px solid ${theme.gray};
  outline: none;
  box-shadow: none;
  transition: all .2s;
  margin-bottom: .75rem;
  line-height: 1.1;
  
  &:focus {
    outline: none;
    border-color: transparent;
    box-shadow: 0 0 0 2px ${theme.primary};
  }
`

export const Card = styled.div`
  background-color: ${props => props.bg ? props.bg : 'white'};
  min-height: ${props => props.minHeight ? `${props.minHeight}px` : 'auto'};
  border-radius: 4px;
  padding: 1.5rem .75rem;
  box-shadow: ${theme.shadow};
  margin-bottom: 2rem;
  text-align: ${props => props.align ? props.align : 'inherit'};
  
  ${bp.sm`padding: 2rem;`}
`

export const Spinner = styled.div`
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 100;
  border: 3px solid #c4a6ff;
  border-top: 3px solid ${theme.primary};
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export const Span = styled.span`
  color: ${props => props.color ? props.color : 'inherit'};
  background-color: ${props => props.bg ? props.bg : 'inherit'};
  font-size: ${props => props.fs ? props.fs : 'inherit'};
`

export const Textarea = styled.textarea`
  width: 100%;
  font-size: inherit;
  font-family: ${theme.fontText};
  padding: .5rem;
  border: 1px solid ${theme.gray};
  border-radius: 4px;
  margin-bottom: .5rem;
  resize: vertical;
  transition: all .2s;
  
  &:focus {
    outline: none;
    border-color: transparent;
    box-shadow: 0 0 0 2px ${theme.primary};
  }
`

export const Badge = styled.span`
  background-color: ${props => props.bg ? props.bg : theme.yellow};
  color: ${props => props.color ? props.color : '#000'};
  font-size: .5rem;
  text-transform: uppercase;
  padding: .15rem .3rem;
  white-space: nowrap;
  border-radius: 4px;
  font-weight: 600;
`

export const RatioBox = styled.div`
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  &:before {
    display: block;
    content: "";
    padding-top: ${props => props.width && props.height ? (props.height / props.width * 100) + "%" : "56.25%"};
  }
  img, iframe, svg, video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`;
