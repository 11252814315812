import React, {useEffect, lazy, Suspense} from 'react';
import {Route, Switch, useRouteMatch} from "react-router-dom";
import {Layout, Main, Spinner} from './styles/shared';
import {connect} from 'react-redux'
import {createStructuredSelector} from "reselect";
import {selectUser, selectUserLoading} from "./store/auth/auth.selectors";
import {selectExamsLoading} from "./store/exams/exams.selectors";
import {checkUser} from "./store/auth/auth.actions";
import {getExams} from "./store/exams/exams.actions";
import {getStudents} from "./store/students/students.actions";
import Header from "./components/header/Header";
import Home from './pages/home/Home';
import Help from './pages/static/Help'
import NotFound from './pages/NotFound'

const Dashboard = lazy(() => import('./pages/dashboard/Dashboard'));
const Signup = lazy(() => import('./pages/signup/Signup'));
const Restore = lazy(() => import('./pages/restore/Restore'));
const ExamContainer = lazy(() => import('./pages/exam/ExamContainer'));
const Recap = lazy(() => import('./pages/recap/Recap'));

const App = ({user, loadingUser, loadingExams, dispatch}) => {
  const match = useRouteMatch("/exam/:id");

  useEffect(() => {
    dispatch(checkUser());
    dispatch(getExams())
  }, [dispatch]);

  useEffect(() => {
    if (user && user.role !== 'student') {
      dispatch(getStudents(user))
    }
  }, [dispatch, user])

  return (
    <Layout>
      {!match && <Header/>}
      <Main noBg={match}>
        <Suspense fallback={<Spinner/>}>
          <Switch>
            <Route exact path="/">{loadingUser ? <Spinner/> : !user ? <Home/> : <Dashboard/>}</Route>
            <Route path="/signup">{loadingUser ? <Spinner/> : !user ? <Signup/> : <Dashboard/>}</Route>
            <Route path="/restore" component={Restore}/>
            <Route path="/help" component={Help}/>
            <Route path="/exam/:id">{loadingExams ? <Spinner/> : <ExamContainer/>}</Route>
            <Route path="/recap/:id" component={Recap}/>
            <Route component={NotFound}/>
          </Switch>
        </Suspense>
      </Main>
    </Layout>
  );
};

const mapStateToProps = createStructuredSelector({
  user: selectUser,
  loadingUser: selectUserLoading,
  loadingExams: selectExamsLoading,
})

export default connect(mapStateToProps, null)(App)