import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {store} from './store/store';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import DocumentTitle from "react-document-title";
import {GlobalStyles} from "./styles/global";
import {Fuego, FuegoProvider} from '@nandorojo/swr-firestore'
import firebaseConfig from "./firebase/config";

const fuego = new Fuego(firebaseConfig)


ReactDOM.render(
  <FuegoProvider fuego={fuego}>
    <Provider store={store}>
      <BrowserRouter>
        <DocumentTitle title="Контрольные работы Даркейну"/>
        <ScrollToTop/>
        <GlobalStyles/>
        <App/>
      </BrowserRouter>
    </Provider>
  </FuegoProvider>,
  document.getElementById('root')
);
