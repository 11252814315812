import React, {Fragment} from 'react';
import ProfileDropdown from "./ProfileDropdown";
import Profile from "./Profile";
import styled from "styled-components";
import {NavLink} from "react-router-dom";
import theme from "../../styles/theme";
import {ExternalLink} from "@styled-icons/feather";

const Nav = ({user, links}) => {
  return (
    <Wrap>
      {links.slice(0, (user ? -1 : links.length)).map(link => (
        <Fragment key={link.path}>
          {link.href ?
            <ALink href={link.path} target="_blank" rel="noopener noreferrer">
              {link.label}&nbsp;<ExternalLink size={15}/>
            </ALink> :
            <NavItem to={link.path}>{link.label}</NavItem>
          }
        </Fragment>
      ))}
      {user &&
      <ProfileDropdown label={user.name}>
        <Profile user={user}/>
      </ProfileDropdown>
      }
    </Wrap>
  );
};

export default Nav;

const Wrap = styled.nav`
  display: flex;
`

const NavItem = styled(NavLink)`
  font-family: ${theme.fontTitle};
  font-size: 110%;
  margin-left: 1.5rem;
  
  &.active {
    color: black;
    pointer-events: none;
  }
`

const ALink = styled.a`
  font-family: ${theme.fontTitle};
  font-size: 110%;
  margin-left: 1.5rem;
  display: flex;
  align-items: center;
`