import React, {useState} from 'react';
import {Input} from "../styles/shared";
import styled from "styled-components";
import {Eye, EyeOff} from "@styled-icons/feather";
import theme from "../styles/theme";

const PasswordInput = ({name, placeholder, onChange, value}) => {
  const [passShown, setPassShown] = useState(false);
  return (
    <Wrap>
      <Input
        type={passShown ? "text" : "password"}
        name={name}
        placeholder={placeholder}
        onChange={e => onChange(e.target.value, e.target.name)}
        maxLength={100}
        value={value}
      />
      <i onClick={() => setPassShown(!passShown)}>
        {passShown ?
          <EyeOff color={theme.primary} size={20}/> :
          <Eye color={theme.primary} size={20}/>
        }
      </i>
    </Wrap>
  );
};

export default PasswordInput;

const Wrap = styled.div`
  position: relative;
  
  input {
    padding-right: 50px;
  }

  i {
    position: absolute;
    display: inline-block;
    top: 11px;
    right: 10px;
    line-height: 0;
    cursor: pointer;
  }
`