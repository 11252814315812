import {combineReducers} from 'redux';
import authReducer from "./auth/auth.reducer";
import examsReducer from "./exams/exams.reducer";
import studentsReducer from "./students/students.reducer";

const rootReducer = combineReducers({
	auth: authReducer,
	exams: examsReducer,
	students: studentsReducer,
})

export default rootReducer