import React, {useState} from 'react';
import {Button, Card, Container, H1, Input, Textarea} from "../../styles/shared";
import DocumentTitle from "react-document-title";
import axios from "axios";
import {showError} from "../../components/Toasts";

const Help = () => {
  const [values, setValues] = useState({email: '', message: ''});
  const [loading, setLoading] = useState(false)
  const [sent, setSent] = useState(false)

  const onChange = e => {
    e.persist();
    setValues(prev => ({...prev, [e.target.name]: e.target.value}))
  }

  const sendData = (email, message) => {
    setLoading(true);

    const body = {
      'Отправитель': email,
      'Сообщение': message,
      formDataNameOrder: '["Отправитель", "Сообщение"]',
      formGoogleSheetName: 'responses',
    };

    let bodyEncoded = Object.keys(body).map(function (k) {
      return encodeURIComponent(k) + "=" + encodeURIComponent(body[k]);
    }).join('&');

    axios.post('https://script.google.com/macros/s/AKfycby46eQdydn21ddk7tKV7h2RCEoHdWRUi1CZVJYNKa0iRXb0kmpJc3T1ic1yNOKIsEx9/exec', bodyEncoded)
      .then(() => {
        setLoading(false);
        setSent(true);
      })
      .catch(e => {
        setLoading(false);
        showError("Что-то пошло не так. Попробуйте позже, пожалуйста");
        console.error("Error when sending message: ", e);
      });
  };

  const onSubmit = e => {
    e.preventDefault();
    return sendData(values.email, values.message);
  }

  return (
    <Container width={1000}>
      <DocumentTitle title="Помощь"/>
      <Card>
        <H1>Служба поддержки Даркейну</H1>
        {!sent &&
        <form onSubmit={onSubmit}>
          <Textarea
            name="message"
            value={values.message}
            onChange={onChange}
            placeholder="Напишите нам о своей проблеме, постараемся помочь"
            maxLength={500}
            rows={10}
            required
          />
          <Input
            name="email"
            type="email"
            value={values.email}
            onChange={onChange}
            placeholder="Ваша почта"
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            title="Например: name@domain.com"
            required
          />

          <Button disabled={loading}>{loading ? 'Отправляем...' : 'Отправить'}</Button>
        </form>
        }
        {sent && <p>Спасибо! Ваше сообщение принято. Обычно мы отвечаем в течение одного-двух рабочих дней</p>}
      </Card>
    </Container>
  );
};

export default Help
