import {examsActionTypes} from './exams.types'
import {firestore} from "../../firebase";

export const getExamsSuccess = exams => ({
  type: examsActionTypes.GET_EXAMS_SUCCESS,
  payload: exams,
});

export const getExams = () => dispatch => {
  try {
    return firestore.collection('exams')
      .onSnapshot((snapshot => {
        const exams = snapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
        dispatch(getExamsSuccess(exams));
      }));
  } catch (err) {
    console.log('Fetching exams error: ', err.message)
  }
};





