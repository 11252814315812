import React from 'react';
import {Button, Container} from "../../styles/shared";
import {Login} from "./Login";
import styled from "styled-components";
import {Link} from "react-router-dom";
import ImgWebp from "../../components/ImgWebp";
import darkeinu from "../../assets/images/logo-darkeinu.svg";
import fjc from "../../assets/images/logo-fjc.png";
import fjcWepb from "../../assets/images/logo-fjc.webp";
import avner from "../../assets/images/logo-or-avner.png";
import avnerWebp from "../../assets/images/logo-or-avner.webp";

const Home = () => (
    <Wrap>
      <Inner>
        <Login/>
        <Button outline={1} block={1} as={Link} to="/signup">Регистрация учителей</Button>
        <Logos>
          <a href="https://www.darkeinu.org/olimpiada/" target="_blank" rel="noopener noreferrer">
            <img height={50} width={70} src={darkeinu} alt="Даркейну"/>
          </a>
          <a href="https://fjc-fsu.org/" target="_blank" rel="noopener noreferrer">
            <ImgWebp height={70} width={70} src={fjc} srcWebp={fjcWepb} alt="Федерация еврейских общин"/>
          </a>
          <ImgWebp height={70} width={70} src={avner} srcWebp={avnerWebp} alt="Ор Авнер"/>
        </Logos>
      </Inner>
    </Wrap>
);

export default Home;

const Wrap = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Inner = styled.div`
  max-width: 450px;
  width: 100%;
`

export const Logos = styled.div`
  padding-top: 1.5rem;
  margin-bottom: 2rem;
  text-align: center;
  
  a {
    margin-right: 1rem;
    font-size: 0;
    display: inline-block;
    vertical-align: middle;
    
    &:hover {
      filter: unset;
    }
  }
  
  img {
    vertical-align: middle;
  }
`