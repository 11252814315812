import React, {memo} from 'react';
import styled from "styled-components";
import theme from "../../styles/theme";

const data = ['Для учеников', 'Для учителей'];

export const TabsSwitch = memo(({tab, setValues}) => {

  const onSwitch = tab => setValues(prev => ({...prev, tab,}));

  return (
    <Tabs>
      {
        data.map((name, i) => (
          <Tab key={i} active={tab === i} onClick={() => onSwitch(i)}>{name}</Tab>
        ))
      }
    </Tabs>
  );
});

export const Tabs = styled.div`
  display: flex;
  justify-content: center;
`

export const Tab = styled.button`
  font-size: 1.1rem;
  font-weight: ${props => props.active ? "600" : "400"};
  font-family: ${theme.fontTitle};
  background-color: ${props => props.active ? "#fff" : theme.light};
  border-width: 1px 1px 0 1px;
  border-color: rgba(0,0,0,.05);
  border-style: solid;
  border-radius: 6px 6px 0 0;
  padding: .6rem 1rem .3rem;
  margin: 0 .5rem;
  position: relative;
  z-index: ${props => props.active ? '1' : '0'};;
  bottom: -1px;
  color: ${props => props.active ? 'inherit' : theme.primary};
  cursor: ${props => props.active ? 'default' : 'pointer'};

  &:focus {
    outline: none;
  }
`