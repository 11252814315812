import {createSelector} from 'reselect'

const examsSelector = state => state.exams

export const selectExams = createSelector(
  [examsSelector],
  exams => exams.exams
);

export const selectExamsLoading = createSelector(
  [examsSelector],
  exams => exams.loading
);