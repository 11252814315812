import React from 'react';
import {ButtonLink, H3} from "../../styles/shared";
import {auth} from "../../firebase";
import styled from "styled-components";
import bp from "../../styles/bp";
import theme from "../../styles/theme";


const Profile = ({user}) => {
  const {role, name, surname, email, location, phone, grade} = user;
  const country = location && location[0].label;
  const city = location && location[1].label;
  const org = location && location[2].label;

  return (
    <Wrap>

      {role === 'teacher' &&
      <Block>
        <H3 crop>{name} {surname}</H3>
        <div>{org}</div>
        <Mute>{country}, {city}</Mute>
        <Mute>{email}<br/>{phone}</Mute>
      </Block>
      }

      {role === 'student' &&
      <Block>
        <H3 crop>{name} {surname}</H3>
        <div>{grade.label}</div>
        <div>{org}</div>
        <Mute>{country}, {city}</Mute>
      </Block>
      }

      <Block>
        <ButtonLink type="button" onClick={() => auth.signOut()}>
          Выйти
        </ButtonLink>
      </Block>

    </Wrap>
  );
};

export default Profile;

const Wrap = styled.div`
  font-size: 150%;
  ${bp.sm`font-size: 90%`};
  padding-top: .5rem;
`

const Block = styled.div`
  padding: .75rem 0;
  border-bottom: 1px solid rgba(0,0,0,.05);
  margin-bottom: -1px;
  
`

const Mute = styled.div`
  color: ${theme.mute};
`