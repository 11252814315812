const theme = {
  primary: "#00ACC1",
  secondary: "#0B4080",
  orange: "#FFA61A",
  yellow: "#FFF200",
  mute: "#6c757d",
  gray: "#ccc",
  light: "#f7f7f7",
  breakpoints: ['768px', '1100px'],
  fontText: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Helvetica Neue", sans-serif',
  fontTitle: '"DIN Next CYR", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Helvetica Neue", sans-serif',
  shadow: `0 6px 9px rgba(50, 50, 93, 0.05), 0 2px 5px rgba(0, 0, 0, 0.1)`,
};

theme.breakpoints.sm = theme.breakpoints[0]
theme.breakpoints.md = theme.breakpoints[1]


export default theme;
