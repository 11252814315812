import React from 'react';
import styled from "styled-components";
import {NavLink} from "react-router-dom";
import { Image } from '../../styles/shared';
import logo from '../../assets/images/logo-exams.svg'

const Logo = () => {
  return (
    <Wrap exact to="/">
      <Image src={logo} alt='Контрольные работы Даркейну'/>
    </Wrap>
  );
};

export default Logo;

const Wrap = styled(NavLink)`
  margin-right: auto;
  
  &.active {
    pointer-events: none;
  }
`
