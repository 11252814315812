import React from 'react';
import styled from "styled-components";
import {createStructuredSelector} from "reselect";
import {selectUser, selectUserLoading} from "../../store/auth/auth.selectors";
import {connect} from "react-redux";
import Logo from "./Logo";
import Nav from "./Nav";

const links = [
  {label: "Помощь", path: "/help", href: false},
]

const Header = ({user, loading}) => {
  const navProps = {links, user};

  return (
    <Wrap>
      <Logo/>
      {!loading && <Nav {...navProps}/>}
    </Wrap>
  );
};

const mapStateToProps = createStructuredSelector({
  user: selectUser,
  loading: selectUserLoading,
})

export default connect(mapStateToProps, null)(Header)

const Wrap = styled.header`
  display: flex;
  align-items: center;
  height: 100px;
  max-width: 1300px;
  width: 100%;
  padding: 0 1rem;
  margin: 0 auto;
`
